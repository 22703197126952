import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center w-screen h-6 border-t border-backgroundColor text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("p", _hoisted_1, [
      _createTextVNode(" © 2023 Hospitoolity. All rights reserved. | "),
      _createVNode(_component_router_link, {
        to: "/TermsOfService",
        class: "text-blue-500 hover:underline"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Terms of Service")
        ]),
        _: 1
      })
    ])
  ]))
}