
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alert',
  props: {
    type: String,
    title: String,
    index: {
      type: Number,
      default: 0
    },
    isFixed: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    alertType: function (): string {
      const t = (this.type) ? this.type.toLowerCase().trim() : ''
      return ((['info', 'success', 'warning', 'danger'].includes(t)) ? t : 'info')
    }
  },
  beforeMount () {
    if (this.type !== 'danger') {
      setTimeout(this.close, 7000)
    }
  },
  emits: ['closed'],
  methods: {
    close () {
      this.$emit('closed')
      this.$store.commit('setGlobalMessages', this.$store.state.globalMessages.filter((message: { index: number }) => message.index !== this.index))
    }
  }
})
