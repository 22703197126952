import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import routesAccount from '@/views/account/router'
import routesStatic from '@/views/static/router'
import routesAuth from '@/views/auth/router'
import { VueCookieNext } from 'vue-cookie-next'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Static',
    component: () => import('../views/static/Static.vue'),
    children: routesStatic,
    meta: {
      title: 'Hospitoolity - HACCP process online',
      description: 'Digitize your HACCP process with Hospitoolity.com. Create your \'Safer Food Better Business\' book online, manage all documentation and food safety effortlessly.',
      locale: 'en',
      metaTags: {
        'og:title': 'Hospitoolity - HACCP process online'
      }
    }
  },
  {
    path: '/app',
    name: 'Account',
    component: () => import('../views/account/Account.vue'),
    children: routesAccount,
    meta: {
      title: 'Hospitoolity - HACCP process online',
      description: 'Digitize your HACCP process with Hospitoolity.com. Create your \'Safer Food Better Business\' book online, manage all documentation and food safety effortlessly.',
      locale: 'en',
      metaTags: {
        'og:title': 'Hospitoolity - HACCP process online'
      }
    },
    beforeEnter: function (to, from, next) {
      if (VueCookieNext.getCookie('token')) {
        next()
      } else {
        store.commit('setLoggedUser', {})
        next({ path: '/auth', query: { redirect: to.path } })
      }
    }
  }, {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/auth/Auth.vue'),
    children: routesAuth,
    meta: {
      title: 'Hospitoolity - HACCP process online',
      description: 'Digitize your HACCP process with Hospitoolity.com. Create your \'Safer Food Better Business\' book online, manage all documentation and food safety effortlessly.',
      locale: 'en',
      metaTags: {
        'og:title': 'Hospitoolity - HACCP process online'
      }
    },
    beforeEnter: (to, from, next) => {
      if (!VueCookieNext.getCookie('token')) next()
      else next({ path: from.path })
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach((to, from) => {
  store.commit('setGlobalMessages', [])
  return true
})

export default router
