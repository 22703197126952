
import { defineComponent, ref, onMounted, onUnmounted, Ref } from 'vue'
import Multiselect from 'vue-multiselect'
import LocaleSwitcher from '@/shared/components/LocaleSwitcher.vue'
import LogoutIcon from '@/shared/components/base-icon/icons/LogoutIcon.vue'
import AccountIcon from '@/shared/components/base-icon/icons/AccountIcon.vue'
import { VueCookieNext } from 'vue-cookie-next'
import { Branch } from '../interfaces/branch.model'

export default defineComponent({
  name: 'Header',
  components: {
    LocaleSwitcher,
    LogoutIcon,
    AccountIcon,
    Multiselect
  },
  emits: ['branchChanged'],
  methods: {
    logout: function (): void {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/auth')
      })
    },
    changeBranch ($event: Branch) {
      this.$emit('branchChanged')
      this.$store.commit('setActiveBranch', $event)
      this.showSidebar = false
    },
    handleOutsideClick (event: Event): void {
      const el = this.$refs.menuButton as HTMLElement
      if (el && !el.contains(event.target as Node) && (event.target as any).className !== 'multiselect__select') {
        this.showSidebar = false
      }
    }
  },
  beforeMount () {
    if (VueCookieNext.getCookie('token')) this.$store.dispatch('getLoggedUser')
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  setup () {
    const showSidebar = ref(false)
    const menuButton: Ref<HTMLElement | null> = ref(null)
    return {
      showSidebar,
      menuButton
    }
  }
})
