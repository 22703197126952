import { Method as apiMethod } from 'axios'

export const dataEndpoints = {
  getTemperatures: {
    method: 'get' as apiMethod,
    url: '/data/temperatures'
  },
  addTemperature: {
    method: 'post' as apiMethod,
    url: '/data/temperatures'
  },
  updateTemperature: {
    method: 'put' as apiMethod,
    url: '/data/temperatures'
  },
  removeTemperature: {
    method: 'delete' as apiMethod,
    url: '/data/temperatures'
  },
  getData: {
    method: 'get' as apiMethod,
    url: '/data'
  },
  addData: {
    method: 'post' as apiMethod,
    url: '/data'
  },
  updateData: {
    method: 'put' as apiMethod,
    url: '/data'
  },
  removeData: {
    method: 'delete' as apiMethod,
    url: '/data'
  },
  getBook: {
    method: 'put' as apiMethod,
    url: '/data/book'
  },
  updateBook: {
    method: 'post' as apiMethod,
    url: '/data/book'
  },
  updateBookField: {
    method: 'put' as apiMethod,
    url: '/data/book'
  },
  addDevice: {
    method: 'post' as apiMethod,
    url: '/data/addDevice'
  },
  deleteDevice: {
    method: 'delete' as apiMethod,
    url: '/data/deleteDevice'
  },
  getDevices: {
    method: 'get' as apiMethod,
    url: '/data/getDevices'
  },
  saveChecklist: {
    method: 'put' as apiMethod,
    url: '/data/checklist'
  },
  checkCleaningSchedules: {
    method: 'get' as apiMethod,
    url: '/data/checkCleaningSchedules'
  }
}
