
import { defineComponent } from 'vue'
import { changeLocale } from '@/i18n'
import Alert from '@/shared/components/Alert.vue'
import Header from '@/shared/components/Header.vue'
import Footer from '@/shared/components/Footer.vue'
import IndicatorIcon from '@/shared/components/base-icon/icons/IndicatorIcon.vue'
import { VueCookieNext } from 'vue-cookie-next'

export default defineComponent({
  name: 'App',
  components: {
    Alert,
    Header,
    IndicatorIcon,
    Footer
  },
  data: function () {
    return {
      key: 0 as number,
      cookieAccepted: false
    }
  },
  computed: {
    hiddenCookiesInfo () {
      return !!VueCookieNext.getCookie('hiddenCookiesInfo')
    }
  },
  methods: {
    hideCookiesInfo () {
      this.cookieAccepted = true
      VueCookieNext.setCookie('hiddenCookiesInfo', 'true')
    }
  },
  beforeMount () {
    const locale = String(localStorage.getItem('language'))
    if (localStorage.getItem('language')) changeLocale(locale)
  }
})
