import { RouteRecordRaw } from 'vue-router'
import store from '@/store'

const routesAccount: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Book',
    component: () => import('./Book.vue')
  }, {
    path: 'data/:action/:type',
    name: 'Temperature',
    component: () => import('./Action.vue')
  }, {
    path: 'data/:action',
    name: 'Action',
    component: () => import('./Action.vue')
  }, {
    path: 'settings',
    name: 'Settings',
    component: () => import('./Settings.vue')
  }
]

export default routesAccount
