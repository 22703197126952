import { authEndpoints } from './auth'
import { branchesEndpoints } from './branches'
import { dataEndpoints } from './data'
import { paymentsEndpoints } from './payments'
import { productsEndpoints } from './products'

export const apiBase = location.origin + '/api' || process.env.VUE_APP_API_BASE || 'base'

export const apiEndpoints = {
  ...authEndpoints,
  ...dataEndpoints,
  ...branchesEndpoints,
  ...paymentsEndpoints,
  ...productsEndpoints
}
