import { Method as apiMethod } from 'axios'

export const branchesEndpoints = {
  getBranches: {
    method: 'get' as apiMethod,
    url: '/branches'
  },
  suspendBranches: {
    method: 'post' as apiMethod,
    url: '/branches/suspend'
  },
  updateBranch: {
    method: 'put' as apiMethod,
    url: '/branches'
  },
  createBranch: {
    method: 'post' as apiMethod,
    url: '/branches'
  },
  access: {
    method: 'post' as apiMethod,
    url: '/branches/access'
  },
  resetAccess: {
    method: 'post' as apiMethod,
    url: '/branches/access/reset'
  }
}
