import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logomobile.png'
import _imports_1 from '@/assets/logo.png'


const _hoisted_1 = { class: "header flex items-center bg-backgroundColor h-17 fixed top-0 w-screen py-0.5 ps-0.5 pe-0.5 z-20 md:ps-7.5 md:pe-4 gap-1" }
const _hoisted_2 = { class: "logo h-full flex items-center lg:me-15 xl:me-38" }
const _hoisted_3 = {
  key: 0,
  class: "w-full fixed top-17 left-0 bg-gray-100 z-50 border-r overflow-y-visible transition-transform transform translate-x-0 md:translate-x-[-100%]"
}
const _hoisted_4 = { class: "h-full hidden xl:flex items-center gap-5 text-lg font-bold" }
const _hoisted_5 = { class: "rightIcons h-full flex items-center ms-auto gap-1" }
const _hoisted_6 = {
  key: 1,
  class: "flex items-center gap-1"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "flex gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_LocaleSwitcher = _resolveComponent("LocaleSwitcher")!
  const _component_AccountIcon = _resolveComponent("AccountIcon")!
  const _component_LogoutIcon = _resolveComponent("LogoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "h-full me-2.5 block lg:hidden cursor-pointer object-contain",
        src: _imports_0,
        alt: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
      }),
      _createElementVNode("img", {
        class: "h-full me-2.5 hidden lg:block cursor-pointer object-contain",
        src: _imports_0,
        alt: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
      }),
      (_ctx.$store.state.loggedUser)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "h-full me-2.5 hidden lg:block py-3.5 cursor-pointer object-contain",
            src: _imports_1,
            alt: "",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/')))
          }))
        : _createCommentVNode("", true),
      (!_ctx.$store.state.loggedUser)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "h-full me-2.5 hidden md:block py-3.5 cursor-pointer object-contain",
            src: _imports_1,
            alt: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/')))
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showSidebar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.$store.state.loggedUser)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: _normalizeClass([{'font-bold': _ctx.$route.path.startsWith('/app')}, "block py-3 border-b hover:bg-primary hover:text-white menuLink"]),
                to: "/app",
                "active-class": "active-link",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showSidebar = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('nav.account')), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            class: _normalizeClass([{'font-bold': _ctx.$route.path === '/'}, "block py-3 border-b hover:bg-primary hover:text-white menuLink"]),
            to: "/",
            "active-class": "active-link",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSidebar = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('nav.home')), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass([{'font-bold': _ctx.$route.path === '/about'}, "block py-3 border-b hover:bg-primary hover:text-white menuLink"]),
            to: "/about",
            "active-class": "active-link",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showSidebar = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('nav.about')), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass([{'font-bold': _ctx.$route.path === '/pricing'}, "block py-3 border-b hover:bg-primary hover:text-white menuLink"]),
            to: "/pricing",
            "active-class": "active-link",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showSidebar = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('nav.pricing')), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_router_link, {
            class: _normalizeClass([{'font-bold': _ctx.$route.path === '/contact'}, "block py-3 border-b hover:bg-primary hover:text-white menuLink"]),
            to: "/contact",
            "active-class": "active-link",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showSidebar = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('nav.contactNav')), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          (
          _ctx.$router.currentRoute.value.matched.length > 0 &&
          _ctx.$router.currentRoute.value.matched[0].name === 'Account'
        )
            ? (_openBlock(), _createBlock(_component_multiselect, {
                key: 1,
                class: "mx-auto w-full block relative sm:hidden sm:mb-0",
                modelValue: _ctx.$store.state.activeBranch,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.$store.state.activeBranch) = $event)),
                options: _ctx.$store.state.branches,
                label: "name",
                selectLabel: _ctx.$t('select'),
                deselectLabel: "",
                selectedLabel: _ctx.$t('selected'),
                placeholder: _ctx.$t('selectOption'),
                "allow-empty": false,
                onSelect: _ctx.changeBranch
              }, {
                noResult: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsMatching')), 1)
                ]),
                noOptions: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsAvailable')), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "options", "selectLabel", "selectedLabel", "placeholder", "onSelect"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.$store.state.loggedUser)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: _normalizeClass([{'text-xl text-primaryActive': _ctx.$route.path.startsWith('/app')}, "hover:text-primaryActive"]),
            to: "/app"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('nav.account')), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        class: _normalizeClass([{'text-xl text-primaryActive': _ctx.$route.path === '/'}, "hover:text-primaryActive"]),
        to: "/"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('nav.home')), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass([{'text-xl text-primaryActive': _ctx.$route.path === '/about'}, "hover:text-primaryActive"]),
        to: "/about"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('nav.about')), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass([{'text-xl text-primaryActive': _ctx.$route.path === '/pricing'}, "hover:text-primaryActive"]),
        to: "/pricing"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('nav.pricing')), 1)
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_router_link, {
        class: _normalizeClass([{'text-xl text-primaryActive': _ctx.$route.path === '/contact'}, "hover:text-primaryActive"]),
        to: "/contact"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('nav.contactNav')), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: _normalizeClass(["xl:hidden hover:text-primaryActive", {'text-primaryActive': _ctx.showSidebar}])
      }, [
        _createElementVNode("button", {
          class: "border p-3 h-11 inline-flex items-center justify-center rounded-md font-bold text-xs md:text-base border-primaryActive text-primaryActive hover:bg-primaryActive hover:text-white",
          ref: "menuButton",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showSidebar = !_ctx.showSidebar))
        }, " Menu ", 512)
      ], 2),
      (
          _ctx.$router.currentRoute.value.matched.length > 0 &&
          _ctx.$router.currentRoute.value.matched[0].name === 'Account'
        )
        ? (_openBlock(), _createBlock(_component_multiselect, {
            key: 0,
            class: "w-64 mx-auto hidden sm:block",
            modelValue: _ctx.$store.state.activeBranch,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.$store.state.activeBranch) = $event)),
            options: _ctx.$store.state.branches,
            label: "name",
            selectLabel: _ctx.$t('select'),
            deselectLabel: "",
            selectedLabel: _ctx.$t('selected'),
            placeholder: _ctx.$t('selectOption'),
            "allow-empty": false,
            onSelect: _ctx.changeBranch
          }, {
            noResult: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsMatching')), 1)
            ]),
            noOptions: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsAvailable')), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "selectLabel", "selectedLabel", "placeholder", "onSelect"]))
        : _createCommentVNode("", true),
      _createVNode(_component_LocaleSwitcher),
      ((_ctx.$store.state.loggedUser && _ctx.$store.state.loggedUser.email))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (
            _ctx.$router.currentRoute.value.matched.length > 0 &&
            _ctx.$router.currentRoute.value.matched[0].name === 'Account' &&
            _ctx.$store.state.loggedUser &&
            _ctx.$store.state.loggedUser.companyName
          )
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: "primary w-11 h-11 py-2 flex justify-center items-center rounded-md",
                  to: "/app/settings"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AccountIcon)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "primary p-0 w-11 h-11",
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, [
              _createVNode(_component_LogoutIcon, { class: "text-white" })
            ])
          ]))
        : (_ctx.$router.currentRoute.value.matched.length > 0 && _ctx.$router.currentRoute.value.matched[0].name !== 'Auth')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  class: "primary p-3 h-11 inline-flex items-center justify-center rounded-md font-bold text-xs md:text-base",
                  to: "/auth/signup"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.signup')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  class: "primary p-3 h-11 inline-flex items-center justify-center rounded-md font-bold text-xs md:text-base",
                  to: "/auth"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.signin')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}