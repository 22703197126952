import { createStore } from 'vuex'
import client from 'axios'
import qs from 'qs'
import { VueCookieNext } from 'vue-cookie-next'
import { apiBase, apiEndpoints } from '@/shared/endpoints/api'
import { User } from '@/shared/interfaces/user.model'
import { DayTemperatures } from '@/shared/interfaces/dayTemperatures.model'
import router from '../router'
import { BookField } from '@/shared/interfaces/bookField.model'
import { Device } from '@/shared/interfaces/device.model'
import { CleaningSchedule } from '@/shared/interfaces/cleaningSchedule.model'
import { Branch } from '@/shared/interfaces/branch.model'
import { Card } from '@/shared/interfaces/card.model'
import { Discount } from '@/shared/interfaces/discount.model'

const parseErrorsToInputErrorMessage = (err: any, formName: string | undefined) => {
  if (err.response) {
    const errors = err.response.data.errors
    errors.forEach((error: { message: string, field: string, errorCode: string }) => {
      if (formName) {
        store.state.vuelidateExternalResults[formName] = {
          [error.field]: [error.errorCode ? error.errorCode : error.message]
        }
      } else {
        store.state.vuelidateExternalResults[error.field] = [error.errorCode ? error.errorCode : error.message]
      }
    })
  }
}

const parseErrorsToTechnicalAlertError = (err: any, message: string) => {
  if (err.response) {
    const errors = err.response.data.errors as { message: string, field: string, errorCode: string }[]
    if (errors && !!errors.find((error: { message: string, field: string, errorCode: string }) => !error.errorCode)) {
      let foundIndex = null
      store.state.globalMessages.forEach((searchedMessage: { type: 'danger' | 'success' | 'info' | 'waring', title?: string, text: string, index: number }, index: number) => {
        if (searchedMessage.text.trim() === message.trim()) foundIndex = index
      })
      if (foundIndex !== null) store.state.globalMessages.splice(foundIndex, 1)
      setTimeout(() => {
        store.commit('setGlobalMessages', [...store.state.globalMessages, {
          type: 'danger',
          title: 'error',
          text: message,
          index: store.state.globalMessages.length
        }])
      }, 100)
    }
  }
}

client.defaults.baseURL = apiBase
client.interceptors.request.use(function (config) {
  const token = VueCookieNext.getCookie('token')
  if (token && token.length > 0) {
    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token
    }
  }
  return config
}, function (error) {
  console.log('error', error)
  return Promise.reject(error)
})

client.interceptors.response.use(function (config) {
  return config
}, function (error) {
  if (
    (error.response.status === 401 || error.response.status === 403) &&
    error.response.config.url.indexOf('logout') < 0 &&
    error.response.config.url.indexOf('login') < 0
  ) store.dispatch('logout')
  if (
    ((error.response.status !== 401 && error.response.status !== 403) ||
    error.response.config.url.indexOf('login') >= 0) &&
    error.response.data.errors && error.response.data.errors.length > 0
  ) {
    const globalErrorsWithCode = error.response.data.errors.filter((err: { field: string, errorCode: string }) => err.field === 'global' && err.errorCode)
    const mappedErrors = globalErrorsWithCode.map((err: { errorCode: string }) => {
      return {
        type: 'danger',
        title: 'error',
        text: err.errorCode,
        index: store.state.globalMessages.length > 0 ? store.state.globalMessages[store.state.globalMessages.length - 1].index + 1 : 0
      }
    })
    mappedErrors.forEach((error: any) => {
      let foundIndex = null
      store.state.globalMessages.forEach((searchedMessage: { type: 'danger' | 'success' | 'info' | 'waring', title?: string, text: string, index: number }, index: number) => {
        if (searchedMessage.text.trim() === error.text.trim()) foundIndex = index
      })
      if (foundIndex !== null) store.state.globalMessages.splice(foundIndex, 1)
    })
    setTimeout(() => {
      store.commit('setGlobalMessages', [...store.state.globalMessages, ...mappedErrors])
    }, 100)
  }
  return Promise.reject(error)
})

const store = createStore({
  state: {
    lang: '' as string,
    loggedUser: {} as User,
    accountInterceptor: null as any,
    globalMessages: [] as { type: 'danger' | 'success' | 'info' | 'waring', title?: string, text: string, index: number }[],
    vuelidateExternalResults: {} as any,
    isColumnsError: {} as any,
    bookFields: [] as BookField[],
    editedDayTemperatures: {} as DayTemperatures,
    data: [] as any[],
    dataCount: null as number | null,
    editedItem: {} as any,
    newItem: {} as any,
    devices: [] as Device[],
    checklist: [] as CleaningSchedule[],
    originalChecklist: [] as CleaningSchedule[],
    branches: [] as Branch[],
    activeBranch: {} as Branch,
    appLoadingText: null as string | null,
    cards: [] as Card[],
    products: [] as any[],
    discounts: [] as Discount[]
  },
  mutations: {
    setLang: (state, newLang) => { state.lang = newLang },
    setLoggedUser: (state, loggedUser: User) => {
      state.loggedUser = loggedUser
      if (loggedUser && loggedUser.email) store.dispatch('getBranches')
    },
    setAccountInterceptor: (state, accountInterceptor: any) => { state.accountInterceptor = accountInterceptor },
    setGlobalMessages: (state, globalMessages: any) => { state.globalMessages = globalMessages },
    setVuelidateExternalResults: (state, vuelidateExternalResults: any) => { state.vuelidateExternalResults = vuelidateExternalResults },
    setIsColumnsError: (state, isColumnsError: any) => { state.isColumnsError = isColumnsError },
    setBookFields: (state, bookFields: BookField[]) => { state.bookFields = bookFields },
    setEditedDayTemperatures: (state, editedDayTemperatures: DayTemperatures) => { state.editedDayTemperatures = editedDayTemperatures },
    setData: (state, data: any[]) => { state.data = data },
    setDataCount: (state, count: number) => { state.dataCount = count },
    setEditedItem: (state, editedItem: any) => { state.editedItem = editedItem },
    setNewItem: (state, newItem: any) => { state.newItem = newItem },
    setDevices: (state, devices: Device[]) => { state.devices = devices },
    setCleaningSchedule: (state, checklist: CleaningSchedule[]) => { state.checklist = checklist },
    setOriginalChecklist: (state, checklist: CleaningSchedule[]) => { state.originalChecklist = checklist },
    setBranches: (state, branches: Branch[]) => { state.branches = branches },
    setActiveBranch: (state, activeBranch: Branch) => {
      localStorage.setItem('branch', JSON.stringify(activeBranch))
      state.activeBranch = activeBranch
    },
    setAppLoadingText: (state, appLoadingText: string | null) => { state.appLoadingText = appLoadingText },
    setCards: (state, cards: Card[]) => { state.cards = cards },
    setProducts: (state, products: any[]) => { state.products = products },
    setDiscounts: (state, discounts: Discount[]) => { state.discounts = discounts }
  },
  actions: {
    setToken: (context, token: string) => {
      VueCookieNext.setCookie('token', token, { path: '/', domain: location.hostname })
      context.dispatch('getLoggedUser')
    },
    removeToken: (context) => {
      VueCookieNext.removeCookie('token', { path: '/', domain: location.hostname })
      VueCookieNext.removeCookie('missingCardsClosed')
      context.commit('setLoggedUser', null)
      context.commit('setData', [])
      context.commit('setDataCount', 0)
      context.commit('setNewItem', null)
      context.commit('setDevices', [])
      context.commit('setCleaningSchedule', [])
      context.commit('setOriginalChecklist', [])
      context.commit('setBranches', [])
      context.commit('setActiveBranch', null)
      context.commit('setCards', [])
      router.push('/auth')
    },
    login: async (context, { email, password, remember }): Promise<string> => {
      const data = {
        email,
        password
      }
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.login.method,
          url: apiEndpoints.login.url,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data)
        }).then(async resp => {
          const token = resp.data.result.token
          context.dispatch('setToken', token)
          resolve(token)
        }).catch(err => {
          context.dispatch('removeToken')
          parseErrorsToInputErrorMessage(err, undefined)
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          console.log(err)
          reject(err)
        })
      })
    },
    logout: async (context) => {
      const userId = context.state.loggedUser ? context.state.loggedUser._id : null
      await client.post(apiEndpoints.logout.url, { body: { userId } }).then(() => {
        context.dispatch('removeToken')
      }).catch(() => {
        context.dispatch('removeToken')
      })
    },
    getLoggedUser: async (context, token) => {
      await client.get(apiEndpoints.me.url).then(response => {
        context.commit('setLoggedUser', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setLoggedUser', null)
      })
    },
    register: async (context, { price, email, password, passwordConfirm, companyName, howManyBranches, shouldRedirect = true, token }, formName = 'registerForm') => {
      const data = {
        price: price.id,
        product: price.product,
        email,
        password,
        password_confirm: passwordConfirm,
        companyName,
        howManyBranches,
        token
      }
      await client({
        method: apiEndpoints.register.method,
        url: apiEndpoints.register.url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data)
      }).then(resp => {
        if (shouldRedirect) router.push('/register-success')
        return resp.data
      }).catch(err => {
        parseErrorsToInputErrorMessage(err, formName)
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        console.log(err)
      })
    },
    activate: (context, data: { email: string, token: string }): Promise<string> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.activate.method,
          url: apiEndpoints.activate.url,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + data.token
          },
          data: qs.stringify({ email: data.email })
        }).then(() => {
          resolve('')
        }).catch(err => {
          parseErrorsToTechnicalAlertError(err, 'technical_problems_contact_us')
          console.log(err)
          reject(err)
        })
      })
    },
    forgot: async (context, email) => {
      const data = {
        email
      }
      await client({
        method: apiEndpoints.forgot.method,
        url: apiEndpoints.forgot.url,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data)
      }).then(resp => {
        const token = resp.data.access_token
      }).catch(err => {
        parseErrorsToInputErrorMessage(err, undefined)
        console.log(err)
      })
    },
    // eslint-disable-next-line
    reset: async (context, { email, token, password, password_confirm }, formName = 'resetForm') => {
      const data = {
        email,
        password,
        password_confirm
      }
      await client({
        method: apiEndpoints.reset.method,
        url: apiEndpoints.reset.url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + token
        },
        data: qs.stringify(data)
      }).then(resp => {
        router.push('/auth')
      }).catch((err: any) => {
        parseErrorsToInputErrorMessage(err, formName)
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        console.log(err)
      })
    },
    getData: async (context, filters) => {
      context.commit('setAppLoadingText', 'loader.gettingData')
      const type = router.currentRoute.value.params.type
      let { action, ...restFilters } = filters
      const localFilters = localStorage.getItem('filters')
      if (localFilters) restFilters = { ...restFilters, ...JSON.parse(localFilters) }
      delete restFilters.sortByColumn
      if (filters.action && filters.action !== router.currentRoute.value.params.action) {
        delete restFilters.sortBy
        delete restFilters.order
        delete restFilters.limit
        delete restFilters.current
        delete restFilters.query
      }
      await client.get(apiEndpoints.getData.url, { params: { action: filters.action || router.currentRoute.value.params.action, type, branch_id: context.state.activeBranch._id, ...restFilters } }).then(async response => {
        if (!filters.action) {
          switch (router.currentRoute.value.params.action) {
            case 'day-temperatures':
              context.commit('setAppLoadingText', 'day-temperatures.gettingDevices')
              await context.dispatch('getDevices')
              context.state.newItem = {
                id: undefined,
                branchId: context.state.activeBranch._id,
                date: new Date(),
                devicesTemperatures: context.state.devices
                  .filter((device: Device) => {
                    const resetedDeviceDate = new Date(device.date)
                    resetedDeviceDate.setHours(0, 0, 0, 0)
                    const resetedDate = new Date()
                    resetedDate.setHours(0, 0, 0, 0)
                    return resetedDeviceDate.getTime() <= resetedDate.getTime()
                  })
                  .map((device: Device) => ({ device_id: device._id, deviceId: device.deviceId, morning: null, evening: null }))
              }
              break
            case 'cleaning':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), checklist: [], name: '' }
              context.dispatch('getData', { action: 'cleaning-schedule' })
              break
            case 'cleaning-schedule':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, thing: '', frequency: '', precautions: '', methodCleaning: '' }
              break
            case 'prove-it':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), safeMethod: '', howToProve: '' }
              break
            case 'contact':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, contactName: '', phoneNumber: '', email: '', address: '', forWhichAdvice: '' }
              break
            case 'training':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), trainingName: '', whoHasBeenTrained: '', whoTrained: '', whoAddRecord: '' }
              break
            case 'delivery':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), supplier: '', product: '', temperature: '', isAccepted: false, comment: '', whoAdded: '' }
              break
            case 'probe':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), probeIcedWater: '', probeBoilingWater: '', whoChecked: '' }
              break
            case 'problem':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), problemAndSolution: '', whoChecked: '' }
              break
            case 'supplier':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, businessName: '', contactName: '', phone: '', email: '', address: '', deliveryDays: '', leadTimePlacingOrder: '', whichGoods: '' }
              break
            case 'opening':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), check0: false, check1: false, check2: false, check3: false, check4: false, check5: false, check6: false, check7: false, check8: false, otherChecks: [], additionalChecks: '', name: '' }
              break
            case 'closing':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), check0: false, check1: false, check2: false, check3: false, check4: false, check5: false, check6: false, check7: false, check8: false, otherChecks: [], additionalChecks: '', name: '' }
              break
            case 'monthly-review':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), check0: false, details: '', whatDidDo: '', check1: false, check2: false, check3: false, check4: false, check5: false, check6: false, check7: false, check8: false, check9: false, check10: false, check11: false, check12: false, additional: '', name: '' }
              break
            case 'dishes-allergen':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, dish: '', containCelery: false, containCereals: false, containCrustaceans: false, containEggs: false, containFish: false, containLupin: false, containMilk: false, containMollusc: false, containMustard: false, containNuts: false, containPeanuts: false, containSesame: false, containSoya: false, containSulphurDioxide: false }
              break
            case 'food-handling':
              context.state.newItem = { id: undefined, branchId: context.state.activeBranch._id, date: new Date(), food: '', choiceCookingCoolingReheating: '', timeStart: new Date(), timeFinish: new Date(), temperature: '', name: '', comment: '' }
              break
          }
          response.data.result.unshift(context.state.newItem)
          const newItemErrorSet = {} as any
          Object.keys(context.state.newItem).forEach((key: string) => {
            if (
              key !== 'id' &&
              key !== 'branchId' &&
              !Array.isArray(context.state.newItem[key]) &&
              JSON.stringify(context.state.newItem[key]) !== 'false' &&
              JSON.stringify(context.state.newItem[key]) !== 'true'
            ) {
              newItemErrorSet[key] = true
            }
          })
          context.commit('setIsColumnsError', { newItem: newItemErrorSet })
          context.commit('setData', response.data.result)
          context.commit('setDataCount', response.data.count)
        } else {
          const actionParts = filters.action.split('-')
          actionParts.forEach((part: string, index: number) => {
            actionParts[index] = part.charAt(0).toUpperCase() + part.slice(1)
          })
          const mutation = 'set' + actionParts.join('')
          context.commit(mutation, response.data.result)
        }
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    setData: async (context) => {
      context.commit('setAppLoadingText', 'loader.settingData')
      const itemToAdd = context.state.newItem
      const type = router.currentRoute.value.params.type
      const filters = localStorage.getItem('filters')
      await client.post(apiEndpoints.getData.url, { ...itemToAdd, action: router.currentRoute.value.params.action, type }, { params: filters ? JSON.parse(filters) : {} }).then(response => {
        response.data.result.unshift(context.state.newItem)
        const newItemErrorSet = {} as any
        Object.keys(context.state.newItem).forEach((key: string) => {
          if (
            key !== 'id' &&
            key !== 'branchId' &&
            !Array.isArray(context.state.newItem[key]) &&
            JSON.stringify(context.state.newItem[key]) !== 'false' &&
            JSON.stringify(context.state.newItem[key]) !== 'true'
          ) {
            newItemErrorSet[key] = true
          }
        })
        context.commit('setIsColumnsError', { newItem: newItemErrorSet })
        context.commit('setData', response.data.result)
        context.commit('setDataCount', response.data.count)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    updateData: async (context) => {
      context.commit('setAppLoadingText', 'loader.settingData')
      const itemToEdit = context.state.editedItem
      const filters = localStorage.getItem('filters')
      await client.put(apiEndpoints.getData.url, { ...itemToEdit, action: router.currentRoute.value.params.action }, { params: filters ? JSON.parse(filters) : {} }).then(response => {
        response.data.result.unshift(context.state.newItem)
        const newItemErrorSet = {} as any
        Object.keys(context.state.newItem).forEach((key: string) => {
          if (
            key !== 'id' &&
            key !== 'branchId' &&
            !Array.isArray(context.state.newItem[key]) &&
            JSON.stringify(context.state.newItem[key]) !== 'false' &&
            JSON.stringify(context.state.newItem[key]) !== 'true'
          ) {
            newItemErrorSet[key] = true
          }
        })
        context.commit('setIsColumnsError', { newItem: newItemErrorSet })
        context.commit('setData', response.data.result)
        context.commit('setDataCount', response.data.count)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    removeData: async (context, _id) => {
      context.commit('setAppLoadingText', 'loader.removingData')
      const filters = localStorage.getItem('filters')
      const type = router.currentRoute.value.params.type
      await client.delete(apiEndpoints.getData.url, { data: { _id, action: router.currentRoute.value.params.action, branchId: context.state.activeBranch._id, type }, params: filters ? JSON.parse(filters) : {} }).then(response => {
        response.data.result.unshift(context.state.newItem)
        const newItemErrorSet = {} as any
        Object.keys(context.state.newItem).forEach((key: string) => {
          if (
            key !== 'id' &&
            key !== 'branchId' &&
            !Array.isArray(context.state.newItem[key]) &&
            JSON.stringify(context.state.newItem[key]) !== 'false' &&
            JSON.stringify(context.state.newItem[key]) !== 'true'
          ) {
            newItemErrorSet[key] = true
          }
        })
        context.commit('setIsColumnsError', { newItem: newItemErrorSet })
        context.commit('setData', response.data.result)
        context.commit('setDataCount', response.data.count)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    addDevice: async (context, data) => {
      await client.post(apiEndpoints.addDevice.url, { ...data, type: router.currentRoute.value.params.type }).then(response => {
        context.commit('setDevices', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    getDevices: async (context) => {
      await client.get(apiEndpoints.getDevices.url, { params: { type: router.currentRoute.value.params.type, branch_id: context.state.activeBranch._id } }).then(response => {
        context.commit('setDevices', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    deleteDevice: async (context, data) => {
      await client.delete(apiEndpoints.deleteDevice.url, { data: { ...data, branch_id: context.state.activeBranch._id, type: router.currentRoute.value.params.type } }).then(response => {
        context.commit('setDevices', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    getBook: async (context) => {
      context.commit('setAppLoadingText', 'book.loadingProcess')
      await client.get(apiEndpoints.getBook.url, { params: { branch_id: context.state.activeBranch._id } }).then(response => {
        context.commit('setBookFields', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getBookFieldsByName: async (context, name) => {
      return client.get(apiEndpoints.getBook.url, { params: { branch_id: context.state.activeBranch._id, name } }).then(response => {
        return response.data.result
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    updateBook: async (context, data: BookField[]) => {
      context.commit('setAppLoadingText', 'loader.savingProcess')
      await client.post(apiEndpoints.updateBook.url, { book_fields: data, branch_id: context.state.activeBranch._id }).then(response => {
        context.commit('setBookFields', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    updateBookField: async (context, data: BookField) => {
      await client.put(apiEndpoints.updateBookField.url, { ...data, branch_id: context.state.activeBranch._id }).then(response => {
        context.commit('setBookFields', response.data.result)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    saveChecklist: async (context, cleaning) => {
      const filters = localStorage.getItem('filters')
      await client.put(apiEndpoints.saveChecklist.url, cleaning, { params: filters ? JSON.parse(filters) : {} }).then(response => {
        response.data.result.unshift(context.state.newItem)
        const newItemErrorSet = {} as any
        Object.keys(context.state.newItem).forEach((key: string) => {
          if (
            key !== 'id' &&
            key !== 'branchId' &&
            !Array.isArray(context.state.newItem[key]) &&
            JSON.stringify(context.state.newItem[key]) !== 'false' &&
            JSON.stringify(context.state.newItem[key]) !== 'true'
          ) {
            newItemErrorSet[key] = true
          }
        })
        context.commit('setIsColumnsError', { newItem: newItemErrorSet })
        context.commit('setData', response.data.result)
        context.commit('setDataCount', response.data.count)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    updateUser: async (context) => {
      context.commit('setAppLoadingText', 'loader.savingUser')
      await client.put(apiEndpoints.updateUser.url, context.state.loggedUser).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getBranches: async (context) => {
      context.commit('setAppLoadingText', 'loader.gettingBranches')
      await client.get(apiEndpoints.getBranches.url).then(response => {
        context.commit('setBranches', response.data.result)
        if (response.data.result.length > 0) {
          const branchFromLocalString = localStorage.getItem('branch')
          let branchFromLocalObj: any
          if (branchFromLocalString) {
            branchFromLocalObj = JSON.parse(branchFromLocalString) as Branch
            branchFromLocalObj = !branchFromLocalObj || branchFromLocalObj === 'null' || !branchFromLocalObj._id ? null : response.data.result.find((branch: Branch) => branch._id === branchFromLocalObj._id)
          } else branchFromLocalObj = null
          if (branchFromLocalObj) context.commit('setActiveBranch', branchFromLocalObj)
          else context.commit('setActiveBranch', response.data.result[0])
        }
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    addBranch: async (context, { name, cardId }) => {
      context.commit('setAppLoadingText', 'loader.settingBranch')
      await client.post(apiEndpoints.getBranches.url, { name, card_id: cardId }).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    // suspendBranch: async (context, _id) => {
    //   await client.post(apiEndpoints.suspendBranches.url, { _id }).then(response => {
    //     context.commit('setLoggedUser', response.data.result)
    //   }, (err) => {
    //     parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
    //   })
    // },
    deleteBranch: async (context, { _id }) => {
      context.commit('setAppLoadingText', 'loader.removingBranch')
      await client.delete(apiEndpoints.getBranches.url, { data: { _id } }).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    resetAccess: async (context, data) => {
      await client.post(apiEndpoints.resetAccess.url, data).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    addAccess: async (context, data) => {
      context.commit('setAppLoadingText', 'loader.settingAccess')
      await client.post(apiEndpoints.access.url, data).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    deleteAccess: async (context, data) => {
      context.commit('setAppLoadingText', 'loader.removingAccess')
      await client.delete(apiEndpoints.access.url, { data }).then(response => {
        context.commit('setLoggedUser', response.data.result)
        context.commit('setAppLoadingText', null)
      }, (err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getCardsDetails: async (context) => {
      context.commit('setAppLoadingText', 'loader.gettingCard')
      await client.get(apiEndpoints.getCardsDetails.url).then(response => {
        context.commit('setCards', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    addCardDetails: async (context, { tokenId, last4, branchesIds }: { tokenId: string, last4: string, branchesIds: string[] }) => {
      context.commit('setAppLoadingText', 'loader.settingCard')
      await client.post(apiEndpoints.addCardDetails.url, { tokenId: tokenId, last4, branchesIds }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    updateCard: async (context, { _id, branchesIds }: { _id: string, branchesIds: string[] }) => {
      context.commit('setAppLoadingText', 'loader.settingCard')
      await client.put(apiEndpoints.updateCard.url, { _id, branchesIds }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    deleteCard: async (context, _id) => {
      context.commit('setAppLoadingText', 'loader.removingCard')
      await client.delete(apiEndpoints.deleteCard.url, { data: { _id } }).then(response => {
        context.commit('setAppLoadingText', null)
        context.dispatch('getCardsDetails')
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    sendContactForm: async (context, { form, shouldRedirect = true, formName = 'contactForm' }) => {
      return new Promise((resolve, reject) => {
        client.post(apiEndpoints.sendContactForm.url, form).then(response => {
          if (shouldRedirect) router.push('/ContactSuccess')
          resolve(response.data)
        }).catch((err) => {
          parseErrorsToInputErrorMessage(err, formName)
          parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
          reject(err)
        })
      })
    },
    checkCleaningSchedules: (context, data: { date: Date, cleaningSchedules: string[] }) => {
      const date = new Date(data.date)
      date.setHours(0, 0, 0, 0)
      return client.get(apiEndpoints.checkCleaningSchedules.url, { params: { date, cleaningSchedules: JSON.stringify(data.cleaningSchedules), branch_id: context.state.activeBranch._id } }).then(response => {
        return response.data.result
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
      })
    },
    getProducts: async (context, filters) => {
      context.commit('setAppLoadingText', 'loader.gettingProducts')
      await client.get(apiEndpoints.getProducts.url, { params: filters }).then(response => {
        context.commit('setProducts', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    },
    getDiscounts: async (context, filters) => {
      context.commit('setAppLoadingText', 'loader.gettingDiscounts')
      await client.get(apiEndpoints.getDiscounts.url, { params: filters }).then(response => {
        context.commit('setDiscounts', response.data.result)
        context.commit('setAppLoadingText', null)
      }).catch((err) => {
        parseErrorsToTechnicalAlertError(err, 'technical_problems_later')
        context.commit('setAppLoadingText', null)
      })
    }
  },
  modules: {
  }
})

export default store
