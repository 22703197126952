
import { defineComponent, ref } from 'vue'
import { locales, changeLocale } from '@/i18n'

export default defineComponent({
  name: 'LocaleSwitcher',
  data: function () {
    return {
      locales: locales,
      showMenu: false
    }
  },
  methods: {
    setLocale (locale: string): void {
      changeLocale(locale)
      this.showMenu = false
    },
    toggleMenu (): void {
      this.showMenu = !this.showMenu
    },
    handleOutsideClick (event: Event): void {
      const el = this.$refs.localeswitcher as HTMLElement
      if (el && !el.contains(event.target as Node)) {
        this.showMenu = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }
})
