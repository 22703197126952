import { RouteRecordRaw } from 'vue-router'

const routesAccount: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home',
    component: () => import('./Home.vue')
  },
  {
    path: 'pricing',
    name: 'Pricing',
    component: () => import('./Pricing.vue')
  },
  {
    path: 'contact',
    name: 'Contact',
    component: () => import('./Contact.vue')
  },
  {
    path: 'about',
    name: 'About',
    component: () => import('./About.vue')
  },
  {
    path: 'contactSuccess',
    name: 'ContactSuccess',
    component: () => import('./ContactSuccess.vue')
  },
  {
    path: 'termsOfService',
    name: 'TermsOfService',
    component: () => import('./TermsOfService.vue')
  },
  {
    path: 'register-success',
    name: 'RegisterSuccess',
    component: () => import('./RegisterSuccess.vue')
  },
  {
    path: 'forgotPasswordSuccess',
    name: 'ForgotPasswordSuccess',
    component: () => import('./ForgotPasswordSuccess.vue')
  }
]

export default routesAccount
